import React from "react";

function Discord(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="22"
      fill="none"
      viewBox="0 0 24 22"
      {...props}
    >
      <path
        fill=""
        d="M8.039.838a16.944 16.944 0 00-5.908.996l-.196.09a.024.024 0 00-.013.012l-.079.2A38.918 38.918 0 00.224 17.88l.026.234.185.144A18.118 18.118 0 008.64 21.56l.28.035h.026a.2.2 0 00.185-.13l1.413-3.775a.197.197 0 00-.16-.266l-.433-.069c-.9-.139-1.78-.383-2.621-.728a9.749 9.749 0 01-2.845-1.78.048.048 0 01.03-.084h.018c.15.056.32.12.48.184.362.152.732.282 1.109.39 1.918.46 3.883.693 5.856.697v-.026.035a25.327 25.327 0 005.85-.696c.378-.108.748-.239 1.11-.39.16-.063.32-.128.48-.184h.017a.048.048 0 01.03.083 9.701 9.701 0 01-2.84 1.774c-.842.344-1.723.588-2.623.727l-.434.067a.199.199 0 00-.16.266l1.413 3.784a.201.201 0 00.186.13h.033l.282-.036a18.117 18.117 0 008.198-3.309l.186-.144.025-.233a38.918 38.918 0 00-1.617-15.746l-.079-.2a.024.024 0 00-.012-.013l-.197-.09a16.944 16.944 0 00-5.908-.995H13.65a.024.024 0 00-.024.02.023.023 0 00.002.016.024.024 0 00.012.01l4.66 2.24a.022.022 0 01.014.028.023.023 0 01-.008.013.024.024 0 01-.015.006 24.518 24.518 0 00-12.592 0 .024.024 0 01-.022-.019.022.022 0 01.013-.026L10.502.885a.024.024 0 00.012-.012.023.023 0 00.001-.015.024.024 0 00-.024-.02H8.04zm8.81 11.588a2.819 2.819 0 01-2.546-4.022c.28-.588.753-1.061 1.34-1.342.299-.142.62-.23.95-.259.084 0 .17-.011.257-.011a2.817 2.817 0 010 5.634h-.002zm-9.74 0a2.818 2.818 0 01-2.546-4.022c.28-.588.754-1.061 1.341-1.342.298-.142.62-.23.949-.259.085 0 .171-.011.258-.011.397 0 .79.084 1.153.248a2.818 2.818 0 011.397 3.774 2.845 2.845 0 01-1.34 1.341 2.77 2.77 0 01-1.212.27z"
      ></path>
    </svg>
  );
}

export default Discord;
