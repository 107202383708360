const theme = {
  palette: {
    primary: {
      main: '#424242',
      contrastText: '#fff',
    },
  },
};

export default theme;
