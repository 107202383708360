import React from "react";
import { connect } from "react-redux";

import AppHeader from "../../../shared/components/AppHeader/AppHeaderContainer";
import MoneyMarketsSection from "./money-markets/MoneyMarketsSectionContainer";
import ProtocolInfoSection from "./protocol-info/ProtocolInfoContainer";
import EearnInterestSection from "./earn-interest/EarnInterestContainer";

const Landing = ({ theme, bscMarkets }) => (
  <div className={` landing ${theme}`}>
    <div className="money-market-wrapper">
      <div className="ellipse_img_vector-wrapper">
        <div className="ellipse_img_vector">
          <div className="ellipse1-wrapper" style={{ position: "relative" }}>
            {[1, 2, 3, 4, 5].map((ball) => (
              <div
                key={ball}
                className={`ball ball${ball}`}
                style={{ position: "absolute" }}
              />
            ))}
          </div>
        </div>
      </div>
      <AppHeader />
      <MoneyMarketsSection bscMarkets={bscMarkets} />
    </div>
    <EearnInterestSection bscMarkets={bscMarkets} />
    <ProtocolInfoSection theme={theme} />
  </div>
);

const mapStateToProps = (state) => ({
  theme: state.landing.theme,
});

export default connect(mapStateToProps)(Landing);
