import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Landing from "./Landing";
let interval = null;

class LandingContainer extends Component {
  state = {
    bscMarkets: [],
  };

  componentDidMount() {
    interval = setInterval(() => {
      fetch("https://apibsc.fortress.loans/api/markets")
        .then((response) => response.json())
        .then((res) => {
          this.setState({
            bscMarkets: (res.data && res.data.markets) || [],
          });
        });
    }, 3000);
  }

  componentWillUnmount() {
    clearInterval(interval);
  }

  render() {
    return <Landing bscMarkets={this.state.bscMarkets} />;
  }
}

LandingContainer.propTypes = {
  tokenData: PropTypes.instanceOf(Object),
};

LandingContainer.defaultProps = {
  tokenData: {},
};

const mapStateToProps = (state) => ({
  tokenData: state.landing.tokenData,
});

export default connect(mapStateToProps, null)(LandingContainer);
