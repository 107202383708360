import React, { Component } from "react";

import EarnInterest from "./EarnInterest";
import api from "../../../../utils/api";

class EarnInterestContainer extends Component {
  render() {
    return <EarnInterest bscMarkets={this.props.bscMarkets} />;
  }
}

export default EarnInterestContainer;
