import FUELIcon from "../../../../assets/icons/fuel.svg";
import FortressIcon from "../../../../assets/icons/tokenicons/fortress.svg";
import USDIcon from "../../../../assets/icons/tokenicons/usd.svg";
import TetherUSDIcon from "../../../../assets/icons/tokenicons/tether-usd.svg";
import BUSDIcon from "../../../../assets/icons/tokenicons/busd.svg";
import BNBIcon from "../../../../assets/icons/tokenicons/bnb.svg";
import ADAIcon from "../../../../assets/icons/tokenicons/ada.png";
import BCHIcon from "../../../../assets/icons/tokenicons/bch.png";
import BETHIcon from "../../../../assets/icons/tokenicons/beth.png";
import BTCBIcon from "../../../../assets/icons/tokenicons/btcb.png";
import DAIIcon from "../../../../assets/icons/tokenicons/dai.png";
import DOTIcon from "../../../../assets/icons/tokenicons/dot.png";
import ETHIcon from "../../../../assets/icons/tokenicons/eth.png";
import FILIcon from "../../../../assets/icons/tokenicons/fil.png";
import LINKIcon from "../../../../assets/icons/tokenicons/link.png";
import LTCIcon from "../../../../assets/icons/tokenicons/ltc.png";
import XRPIcon from "../../../../assets/icons/tokenicons/xrp.png";
import ftsBnbIcon from "../../../../assets/icons/tokenicons/cake-lp-fts-bnb-v2.png";
import maticIcon from "../../../../assets/icons/tokenicons/matic.png";

export const TokenIconData = {
  FUEL: FUELIcon,
  FTS: FortressIcon,
  SXP: FUELIcon,
  XVS: FortressIcon,
  USDC: USDIcon,
  USDT: TetherUSDIcon,
  BUSD: BUSDIcon,
  BNB: BNBIcon,
  BTCB: BTCBIcon,
  ETH: ETHIcon,
  LTC: LTCIcon,
  XRP: XRPIcon,
  BCH: BCHIcon,
  DOT: DOTIcon,
  LINK: LINKIcon,
  DAI: DAIIcon,
  FIL: FILIcon,
  BETH: BETHIcon,
  ADA: ADAIcon,
  "Cake-LP-FTS-BNB-v2": ftsBnbIcon,
  "Cake-LP": ftsBnbIcon,
  MATIC: maticIcon,
  WBTC: BTCBIcon,
  WETH: ETHIcon,
};
